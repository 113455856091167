import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";

function Ourfocus({data}) {
    return (
        <div className="tekrevol_ourforce p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.ourforce_body_txt}>
                            <h2>{checkData(data, 'ourforce_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <h5 className="strong">{checkData(data, 'ourforce_subtitle')}</h5>
                        <p>{checkData(data, 'ourforce_subtitle')}</p>
                        <Link to={checkData(data, 'ourforce_btn_url')}
                              className="border_btn">{checkData(data, 'ourforce_btn_txt')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Ourfocus;